import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData, SortDirectionType } from "services/common"

export interface UserDetailType {
  dialerCode: string
  emailId: string
  fullName: string
  isAdmin: boolean
  isRboHost: boolean
  isHost: boolean
  isTraveler: boolean
  mobileNo: string
  userId: number
  isActive: boolean
  isDeleted: boolean
}

interface GetPaginatedUserDetailResponseDataData extends Pageable {
  content: UserDetailType[]
}

interface GetPaginatedUserDetailResponseData extends ResponseData {
  data: GetPaginatedUserDetailResponseDataData
}

interface GetPaginatedUserDetailResponse extends AxiosResponse {
  data: GetPaginatedUserDetailResponseData
}

export interface GetPaginatedUserDetailRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    mUserId: string
    noOfRecords: number
    pageNo: number
    pmcId: string | number
    sortBy: string
    sortDirection: SortDirectionType
  }
}

export function getPaginatedUserDetailApi(
  { params, ...config }: GetPaginatedUserDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedUserDetailResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/user`,
    ...config,
  })
}
