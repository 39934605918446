import useSWR from "swr"

import { reviewManagementClient } from "services/clients/reviewManagementClient"

const getReviewsFetcher = async () => {
  const reviewData = await reviewManagementClient.reviewNotification()
  return reviewData.data.data
}

const useReviewNotification = () => {
  return useSWR({ url: `/newadmin/review-notification` }, getReviewsFetcher, {
    revalidateOnFocus: false,
    dedupingInterval: 3000,
    errorRetryCount: 3,
  })
}

export default useReviewNotification
