import { AxiosInstance } from "axios"

import { getPaginatedReviewApi } from "api/reviewManagement/getPaginatedReviewApi"
import { getReviewByBookingNumberApi } from "api/reviewManagement/getReviewByBookingNumberApi"
import { reviewNotificationApi } from "api/reviewManagement/reviewNotificationApi"
import { saveSurveyFeedbackApi } from "api/reviewManagement/saveSurveyFeedbackApi"
import defaultAxiosInstance from "services/client"

export class ReviewManagementClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  // --------- Review Management module APIs -----------
  getPaginatedReview(request: Parameters<typeof getPaginatedReviewApi>[0]) {
    return getPaginatedReviewApi(request, this.axiosInstance)
  }

  // --------- Survey Feedback module APIs -----------
  saveSurveyFeedback(request: Parameters<typeof saveSurveyFeedbackApi>[0]) {
    return saveSurveyFeedbackApi(request, this.axiosInstance)
  }

  getReviewByBookingNumber(
    request: Parameters<typeof getReviewByBookingNumberApi>[0],
  ) {
    return getReviewByBookingNumberApi(request, this.axiosInstance)
  }

  reviewNotification() {
    return reviewNotificationApi(this.axiosInstance)
  }
}

export const reviewManagementClient = new ReviewManagementClient(
  defaultAxiosInstance,
)
