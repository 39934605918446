import { AxiosInstance, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

interface ReviewNotificationResponseData extends ResponseData {
  data: {
    isStatusPending: boolean
  }
}

interface ReviewNotificationResponse extends AxiosResponse {
  data: ReviewNotificationResponseData
}

export function reviewNotificationApi(
  axiosInstance: AxiosInstance,
): Promise<ReviewNotificationResponse> {
  return axiosInstance({
    method: "get",
    url: `newadmin/review-notification`,
  })
}
