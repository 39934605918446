import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

interface SaveSurveyFeedbackApiResponse extends AxiosResponse {
  data: {
    data: string
    apiStatus: string
  }
}

export interface SaveSurveyFeedbackApiRequest
  extends Partial<AxiosRequestConfig> {
  data: {
    surveyId: number
    feedback: string
    status: string
  }
}

export function saveSurveyFeedbackApi(
  { data, ...config }: SaveSurveyFeedbackApiRequest,
  axiosInstance: AxiosInstance,
): Promise<SaveSurveyFeedbackApiResponse> {
  return axiosInstance({
    method: "post",
    data,
    url: `/newadmin/save-survey-feedback`,
    ...config,
  })
}
