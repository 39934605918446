import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ResponseData } from "services/common"

export interface ReviewByBookingNumberType {
  surveyId: number
  propertyName: string
  userName: string
  reviewStatusUpdatedBy: string
  hostReplyStatusUpdatedBy: string
  bookingNumber: string
  hostReply: string
  guestReview: string
  hostReplyDate: number // Unix timestamp (milliseconds)
  pmcId: string
  reviewStatus: string // Assuming possible statuses
  travellerReviewDate: number // Unix timestamp (milliseconds)
  hostReplyStatus: string // Assuming possible statuses
  adminFeedback: string
  adminHostReplyFeedback: string
  surveyRatings: number
  adminActionLogs: {
    adminName: string
    createdDt: number // Unix timestamp (milliseconds)
    hostReplyStatus: string
    reviewStatus: string // Assuming possible statuses
    reviewerId: number
  }[]
  reviewReportDetails: string | null
}

interface GetReviewByBookingNumberResponseData extends ResponseData {
  data: ReviewByBookingNumberType
}

interface GetReviewByBookingNumberResponse extends AxiosResponse {
  data: GetReviewByBookingNumberResponseData
}

export interface GetReviewByBookingNumberRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    bookingNumber: string
  }
}

export function getReviewByBookingNumberApi(
  { params, ...config }: GetReviewByBookingNumberRequest,
  axiosInstance: AxiosInstance,
): Promise<GetReviewByBookingNumberResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/get-review-by-booking-number`,
    ...config,
  })
}
