import { AxiosInstance } from "axios"

import { getPMCListApi, getPMSListApi } from "api/auth/getPmcIdList"
import { getPaginatedEventLogApi } from "api/EventsPage/getPaginatedEventLogApi"
import { getPaginatedFeeCriteriaApi } from "api/feeMapping/getPaginatedFeeCriteriaApi"
import { getPaginatedPropertyDetailApi } from "api/propertyDashboard/getPaginatedPropertyDetailApi"
import { deleteUserDashboardsApi } from "api/userDashboard/deleteUserDashboardApi"
import { getPaginatedUserDetailApi } from "api/userDashboard/getPaginatedUserDetailApi"
import { resetUserDashboardsApi } from "api/userDashboard/resetUserDashboardApi"
import defaultAxiosInstance from "services/client"

export class CommonClient {
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  getPMCList() {
    return getPMCListApi(this.axiosInstance)
  }

  getPMSList() {
    return getPMSListApi(this.axiosInstance)
  }

  // --------- User Dashboard module APIs ---------
  getPaginatedUserDetail(
    request: Parameters<typeof getPaginatedUserDetailApi>[0],
  ) {
    return getPaginatedUserDetailApi(request, this.axiosInstance)
  }

  deleteUserDashboards(request: Parameters<typeof deleteUserDashboardsApi>[0]) {
    return deleteUserDashboardsApi(request, this.axiosInstance)
  }

  resetUserDashboards(request: Parameters<typeof resetUserDashboardsApi>[0]) {
    return resetUserDashboardsApi(request, this.axiosInstance)
  }

  // --------- Events module APIs ---------
  getPaginatedEventLog(request: Parameters<typeof getPaginatedEventLogApi>[0]) {
    return getPaginatedEventLogApi(request, this.axiosInstance)
  }

  // --------- Property Dashboard module APIs -----------
  getPaginatedPropertyDetail(
    request: Parameters<typeof getPaginatedPropertyDetailApi>[0],
  ) {
    return getPaginatedPropertyDetailApi(request, this.axiosInstance)
  }

  // --------- Fee Mapping module APIs -----------
  getPaginatedFeeCriteria(
    request: Parameters<typeof getPaginatedFeeCriteriaApi>[0],
  ) {
    return getPaginatedFeeCriteriaApi(request, this.axiosInstance)
  }
}

export const apiCommonClient = new CommonClient(defaultAxiosInstance)
