import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface ResetUserDashboardResponse extends AxiosResponse {
  data: {
    isFirstLogin: boolean
    message: string
  }
}

export interface ResetUserDashboardRequest extends Partial<AxiosRequestConfig> {
  params: {
    userId: number
  }
}

export function resetUserDashboardsApi(
  { params, ...config }: ResetUserDashboardRequest,
  axiosInstance: AxiosInstance,
): Promise<ResetUserDashboardResponse> {
  return axiosInstance({
    params,
    method: "delete",
    url: `user/rbo/reset`,
    ...config,
  }) as Promise<ResetUserDashboardResponse>
}
