import { ReactNode } from "react"

import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

type CustomModalPropType = {
  children: ReactNode
  errorMessage?: string
  errorModel?: boolean
  isOpen: boolean
  maxH?: string
  maxW?: string
  onClose: () => void
  size?: string
  title?: string | ReactNode
  isCentered?: boolean
}
const CustomModal = ({
  title,
  children,
  isOpen,
  onClose,
  maxH = "600px",
  errorModel = false,
  errorMessage = "",
  size,
  maxW,
  isCentered = false,
}: CustomModalPropType) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent maxH={maxH} maxW={maxW} overflow='auto'>
        <ModalHeader display={"flex"} alignItems='center' pt={4}>
          <>
            {errorModel && <Box h={"20px"} />}
            {title ? (
              <Text size='lg3' fontWeight={700} fontSize='20px'>
                {title}
              </Text>
            ) : (
              <Box h={"20px"} />
            )}
          </>
          <ModalCloseButton mt='5px' />
        </ModalHeader>
        <Divider />
        <ModalBody width={"100%"} px={6} pb={6} pt={"24px"}>
          {errorModel ? <Text>{errorMessage}</Text> : children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CustomModal
